<template>
    <div class="content">
        <div class="header">
            <div class="title">{{ $t('meeting_session.presence') }}</div>
            <slot name="header_menu_right"></slot>
        </div>
        <div class="body">
            <div class="container-form">
                <template v-if="loaded">
                    <div class="sub-header-actions" v-if="$parent.can_lead">
                        <div class="new-items">
                            <div class="input-box">
                                <div class="icon-left"><icon-people /></div>
                                <multiselect 
                                    class="hide-tags multiselect_checkbox"
                                    v-model="guestsValue"
                                    open-direction="bottom"
                                    :close-on-select="false"
                                    :taggable="true"
                                    :options="cloneOptionsUsers"
                                    :allow-empty="true"
                                    :show-labels="false"
                                    :multiple="true"
                                    :maxHeight="200"
                                    track-by="id" 
                                    label="name"
                                    @tag="addNewGuest"
                                    @input="handleDebounceGuests"
                                >
                                    <template slot="placeholder" slot-scope="props">
                                        <span class="text">
                                            {{ $t('add_meeting.choose_participants')}}
                                        </span>
                                    </template>
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
                                        <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc" v-bind:class="{ captain: props.option.id == guests.find(el => el.right == 'captain').id }">
                                            <span class="option__title">{{ props.option.name }}</span>
                                            <span class="option__icon" v-if="props.option.id == guests.find(el => el.right == 'captain').id"><icon-user-crowned/></span>
                                        </div>
                                    </template>
                                    <template slot="noResult">{{$t('departments.no-results')}}</template>
                                    <template slot="noOptions">{{$t('departments.empty-list')}}</template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                    
                    <template>
                        <div class="table-list">
                            <div class="row-item" v-for="guest in guests">
                                <div class="column-avatar big">
                                    <img :src="guest.avatar" v-if="guest.avatar">
                                    <div class="icon-user" v-else>
                                        <icon-user />
                                    </div>
                                </div>
                                <div class="column-name" v-if="guest.name">
                                    {{ guest.name }}
                                </div>
                                <div class="column-name" v-else>
                                    {{ guest.email }}
                                </div>
                                <div class="column-actions">
                                    <template v-if="$parent.can_lead">
                                        <template v-if="emailsSent.includes(guest.email)">
                                            <div class="text-email">{{ $t('meeting_session.email_sent') }}</div>
                                            <div class="send-email sent" @click="sendEmail(guest.email)">
                                                <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 0, hide: 0}">
                                                    <icon-plane/>
                                                    
                                                    <template slot="popover">
                                                        <div class="simple-text">{{ $t('meeting_session.resend_email') }}</div>
                                                    </template>
                                                </v-popover>
                                            </div>
                                            
                                        </template>
                                        
                                        <div class="send-email" :id="`user-${guest.email}`" @click="sendEmail(guest.email)" v-else>
                                            <icon-plane/>
                                            <div class="text">{{ $t('meeting_session.send_email') }}</div>
                                        </div>
                                    </template>

                                    <template v-if="$parent.can_lead">
                                        <div class="captain" v-if="guest.right == 'captain'">
                                            <icon-user-crowned/>
                                        </div>
                                        <div class="subCaptain" @click="updateUserRight(guest)" v-else>
                                            <icon-editors v-if="!guest.is_external && guest.id" :class="{secund: guest.right == 'second'}"/>
                                        </div>
                                    </template>
                                    
                                    <template v-if="$parent.can_lead">
                                        <div class="dropdown w-110">
                                            <div class="open-menu" id="dropdownUser4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div class="status-presence" :class="{present: guest.response == 'yes', absent: guest.response == 'no', maybe: guest.response == 'maybe'}"></div>
                                                <div class="text">{{ !guest.response ? $t('meeting_session.presence_status.unconfirmed') : $t(`meeting_session.presence_status.${guest.response}`)  }}</div>
                                                <div class="icon"><icon-arrow /></div>
                                            </div>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownUser4">
                                                <div class="menu-items">
                                                    <div class="menu-item" @click="updatePresence(guest, 'yes')">{{ $t('meeting_session.presence_status.yes') }}</div>
                                                    <div class="menu-item" @click="updatePresence(guest, 'no')">{{ $t('meeting_session.presence_status.no') }}</div>
                                                    <div class="menu-item" @click="updatePresence(guest, 'holiday')">{{ $t('meeting_session.presence_status.holiday') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="open-menu">
                                            <div class="status-presence" :class="{present: guest.response == 'yes', absent: guest.response == 'no', maybe: guest.response == 'maybe'}"></div>
                                            <div class="text">{{ !guest.response ? $t('meeting_session.presence_status.unconfirmed') : $t(`meeting_session.presence_status.${guest.response}`)  }}</div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                    </template>
                    <slot name="form_submit"></slot>
                </template>
                <LoaderStepMeeting v-else/>
            </div>
        </div>
    </div>

    
</template>

<script>
import IconList from '@/components/Icons/List'
import IconGrid from '@/components/Icons/Grid'
import IconArrow from '@/components/Icons/Arrow'
import IconPeople from '@/components/Icons/People'
import IconUser from '@/components/Icons/User'
import IconUserCrowned from '@/components/Icons/UserCrowned'
import IconEditors from '@/components/Icons/Editors'
import IconPlane from '@/components/Icons/Plane'
import IconClose from '@/components/Icons/Close'
import IconCheck from '@/components/Icons/Check'
import IconQuestion from '@/components/Icons/Question'
import LoaderStepMeeting from '@/components/PagesLoaders/MeetingStep'
import { debounce } from "debounce";


export default {
    components: {
        IconList,
        IconGrid,
        IconArrow,
        IconPeople,
        IconUserCrowned,
        IconEditors,
        IconUser,
        IconPlane,
        IconClose,
        IconCheck,
        IconQuestion,
        LoaderStepMeeting
    },
    props: {
        optionsUsers: Array
    },
    data() {
        return {
            loaded: false,
            loadedFinish: false,
            guests: [],
            externalGuests: [],
            loadedSubmit: false,
            emailsSent: [],
            guestsValue: [],
            loaded: false,
            cloneOptionsUsers: []
        }
    },
    async mounted() {
        // clone options users 
        await this.getUsersLobby();

        if(this.$cookies.get('emailsSent')){ 
            var emailSentCookie = JSON.parse(this.$cookies.get('emailsSent'));
            this.emailsSent = emailSentCookie.map(el => { return el.email });
        }

        this.$root.$on('updatePresenceMeeting', (userData) => {
            // found user by email and update response
            var foundIndex = this.guests.findIndex(el => el.email == userData.email);
            this.guests[foundIndex].response = userData.response;
        })

        this.$root.$on('updateMeetingInvites', (guestsData) => {
            this.guests = guestsData;
            this.guestsValue = this.guests.slice();
            this.getUsers();
        })

        this.$root.$on('changeMeetingCaptain', (data) => {
            // found actual captain and make second
            var foundCaptain = this.guests.findIndex(el => el.right == 'captain');
            this.guests[foundCaptain].right = 'second';

            // // found user by id and update captain
            var foundIndex = this.guests.findIndex(el => el.id == data.id);
            this.guests[foundIndex].right = 'captain';
        })
    },
    beforeDestroy() {
        this.$root.$off("updatePresenceMeeting");
        this.$root.$off("updateMeetingInvites");
        this.$root.$off("changeMeetingCaptain");
    },
    created() { 
        this.handleDebounceGuests = debounce( () => { this.updateGuests(); }, 1000)
    },
    methods: {
        async getUsersLobby() {
            await axios.get(`/public/meeting-session/${this.$route.params.uuid}/users-lobby`)
            .then(({data}) => {
                if(this.$auth.check()){
                    this.cloneOptionsUsers = this.optionsUsers.slice();
                } else {
                    this.cloneOptionsUsers = data.data;
                }
                this.guests = data.data;
                this.guestsValue = this.guests.slice();
            })
            .finally(() => {
                this.getUsers();
            })
        },
        getUsers(){
            this.cloneOptionsUsers.find(el=> el.id == this.guests.find(el => el.right == 'captain').id).$isDisabled = true;

            this.guestsValue.filter(el => !el.type).map(el => {
                this.addNewGuest(el.email, true)
            })

            this.loaded = true;
        },
        updatePresence(guest, responseType) {
            axios.post(`/meeting-session/${this.$parent.meetingLog.id}/update-presence`, {
                token: guest.token_link,
                invite_type: guest.type ? guest.type : 'external_invite',
                response: responseType
            })
            .then(() => {
                if(this.guests.find(el => el.token_link == guest.token_link)) { 
                    this.guests.find(el => el.token_link == guest.token_link).response = responseType;
                } else {
                    this.getUsersLobby();
                }
            })
        },
        changeStepFunction(direction, stepNo = false) {
            if(direction) { 
                this.$emit(direction);
            } else if(stepNo) {
                this.$emit('goToStep', stepNo)
            }
        },
        updateUserRight(user){
            const foundItem = this.guestsValue.find(el => el.id === user.id);
            const newRight = foundItem.right == 'second' ? null : 'second';
            if (foundItem) {
                foundItem.right = newRight;
            } else {
                this.guestsValue.push({ ...user, right: 'second' });
            }

            const foundItemGuestIndex = this.guests.findIndex(el => el.id === user.id);

            this.guests[foundItemGuestIndex].right = newRight;

            this.handleDebounceGuests();
        },
        addNewGuest(newGuest, onLoad = false) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newGuest)) {
                const timestamp = Date.now().toString();
                const randomString = Math.random().toString(36).substring(2, 8);
                const uniqueString = timestamp + randomString;

                const guest = { name: newGuest, id: uniqueString, is_external: true }
                const externalGuest = { email: newGuest }

                // this.cloneOptionsUsers.push(guest)
                this.externalGuests.push(externalGuest)
                this.emailError = false
                
                if(!onLoad) {
                    this.guestsValue.push(guest);
                    this.handleDebounceGuests();
                } else {
                    var foundIndexExternalGuest = this.guestsValue.findIndex(el => !el.type && el.email == newGuest);
                    this.guestsValue[foundIndexExternalGuest] = guest;
                }
            } else {
                this.emailError = true
            }
        },
        updateGuests() {
            var internalUsers = [];
            this.guestsValue.map(el => { 
                if(el.is_external) { return; }
                internalUsers.push( { id: el.id, right: el.right ? el.right : null } );
            })

            axios.post(`/meeting-session/${this.$parent.meetingLog.id}/manage-invites`, {
                users: internalUsers,
                external_invites: this.externalGuests
            }).then(({data}) => {
                this.guests = data.data;
            })
        },
        sendEmail(email){
            if(!this.emailsSent.includes(email)){
                this.emailsSent.push(email);
    
                var arrayEmailsSentCookies = this.$cookies.get('emailsSent') ? JSON.parse(this.$cookies.get('emailsSent')) : [];
                var objEmailSent = { email: email, meeting_session: this.$parent.meetingLog.id}
                arrayEmailsSentCookies.push(objEmailSent);
    
                this.$cookies.set('emailsSent', JSON.stringify(arrayEmailsSentCookies), "1h");
            }

            axios.post(`/meeting-session/${this.$parent.meetingLog.id}/send-invite`, {
                custom_email: email
            })
        }
    }
}
</script>